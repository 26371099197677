import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    console.log('Theme controller connected');
  }

  toggle() {
    if (document.documentElement.classList.contains('dark')) {
      document.documentElement.classList.remove('dark');
      localStorage.theme = 'light';
    } else {
      document.documentElement.classList.add('dark');
      localStorage.theme = 'dark';
    }

    console.log('Theme toggled 2');
  }
}
