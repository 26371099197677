// Compare to ../ServerRouterApp.jsx
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import routes from '../routes/routes';
import { useCable } from '../components/Calendar/hooks/useCable';

function RouterApp(_props) {
  return <BrowserRouter>{routes}</BrowserRouter>;
}

export default RouterApp;
