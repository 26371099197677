import { useCallback, useEffect } from 'react';
import { createConsumer } from '@rails/actioncable';
import { useQueryClient } from '@tanstack/react-query';

export const useCable = () => {
  const queryClient = useQueryClient();

  const onConnected = useCallback(() => {
    console.log('[Calendar WebSocket] Connected');
  }, []);

  const onDisconnected = useCallback(() => {
    console.log('[Calendar WebSocket] Disconnected');
  }, []);

  const onReceived = useCallback(
    (message: any) => {
      const { type } = message;
      console.log(`[Calendar WebSocket] Received: ${type}`);

      // Invalidate queries when meetings are updated
      if (type === 'meetings_updated') {
        console.log('[Calendar WebSocket] Invalidating meetings and calendars queries');

        queryClient.invalidateQueries({ queryKey: ['meetings'] });
        queryClient.invalidateQueries({ queryKey: ['calendars'] });
      }
    },
    [queryClient],
  );

  useEffect(() => {
    console.log('[Calendar WebSocket] Creating connection...');

    const cable = createConsumer();

    const subscription = cable.subscriptions.create('CalendarChannel', {
      connected: onConnected,
      disconnected: onDisconnected,
      received: onReceived,
    });

    return () => {
      console.log('[Calendar WebSocket] Destroying connection...');
      subscription.unsubscribe();
      cable.disconnect();
    };
  }, [onConnected, onDisconnected, onReceived]);
};
