import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { useCable } from '../components/Calendar/hooks/useCable';

const CableProvider = ({ children }: { children: React.ReactNode }) => {
  useCable();

  return children;
};

/* eslint-disable react/prefer-stateless-function */
function Layout({ children }: { children: React.ReactNode }) {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <CableProvider>{children}</CableProvider>
    </QueryClientProvider>
  );
}

export default Layout;
