import React, { useCallback, useState } from 'react';
import { CalendarView, Meeting } from './types';
import { useCalendar } from './hooks/useCalendar';
import { Sidebar } from './components/Sidebar';
import { CalendarHeader } from './components/CalendarHeader';
import { MonthView } from './components/MonthView';
import { WeekView } from './components/WeekView';
import { AddEventModal } from './components/AddEventModal';
import { AgendaView } from './components/AgendaView';
import { MeetingDetailsModal } from './components/MeetingDetailsModal';
import { InlineEventViewer } from './components/InlineEventViewer';
import { InlineMeetingEditor } from './components/InlineMeetingEditor';
import axios from 'axios';

function Calendar() {
  const [currentView, setCurrentView] = useState<CalendarView>('week');
  const {
    selectedCalendars,
    currentTimezone,
    currentDate,
    meetings,
    calendars,
    handleCalendarToggle,
    setCurrentDate,
    fetchMeetings,
  } = useCalendar();

  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState<Meeting | null>(null);
  const [showEditor, setShowEditor] = useState(false);
  const [editorPosition, setEditorPosition] = useState({ top: 0, left: 0 });
  const [draftMeeting, setDraftMeeting] = useState<{
    id?: number;
    startTime: Date;
    endTime: Date;
    top: number;
    height: number;
    dayDate: Date;
  } | null>(null);

  const goToPreviousMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const goToNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const goToToday = () => {
    setCurrentDate(new Date());
  };

  const goToPreviousWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 7);
    setCurrentDate(newDate);
  };

  const goToNextWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 7);
    setCurrentDate(newDate);
  };

  const onMeetingClick = (meeting: Meeting) => {
    console.log('onMeetingClick', JSON.stringify(meeting, null, 2));

    setSelectedMeeting(meeting);

    const meetingElement = document.getElementById(`meeting-${meeting.id}`);
    if (meetingElement) {
      const rect = meetingElement.getBoundingClientRect();
      setEditorPosition({ top: rect.top, left: rect.left - 330 });
    }
  };

  const onCreateMeeting = (startTime: Date, endTime: Date, dayDate: Date) => {
    console.log('Creating meeting:', { startTime, endTime, dayDate });
  };

  const onDeleteMeeting = useCallback((meeting: Meeting) => {
    axios.delete(`/meetings/${meeting.id}`).then(() => {
      setSelectedMeeting(null);
      fetchMeetings();
    });
  }, []);

  const onEditMeeting = useCallback((meeting: Meeting) => {
    setShowEditor(true);

    console.log('Editing meeting:', meeting);

    const meetingElement = document.getElementById(`meeting-${meeting.id}`);
    if (meetingElement) {
      console.log('Meeting element:', meetingElement);
      const rect = meetingElement.getBoundingClientRect();
      setEditorPosition({ top: rect.top, left: rect.left - 330 });
      setDraftMeeting({
        ...meeting,
        id: Number(meeting.id),
        startTime: new Date(meeting.starts_at),
        endTime: new Date(meeting.ends_at),
        top: rect.top,
        height: rect.height,
        dayDate: new Date(meeting.starts_at),
      });
      setSelectedMeeting(null);
    } else {
      console.error('Meeting element not found');
    }
  }, []);

  return (
    <div className="bg-white dark:bg-gray-800 h-full flex-grow">
      <div className="flex h-full">
        <div className="flex-none w-64 h-full">
          <Sidebar
            calendars={calendars}
            selectedCalendars={selectedCalendars}
            onCalendarToggle={handleCalendarToggle}
            onOpenAddEventModal={() => setIsAddEventModalOpen(true)}
          />
        </div>

        <div className="right-calendar-container flex-grow flex flex-col h-full overflow-auto">
          <CalendarHeader
            currentDate={currentDate}
            currentView={currentView}
            currentTimezone={currentTimezone}
            setCurrentView={setCurrentView}
            goToToday={goToToday}
            goToPrevious={currentView === 'month' ? goToPreviousMonth : goToPreviousWeek}
            goToNext={currentView === 'month' ? goToNextMonth : goToNextWeek}
          />

          {(() => {
            switch (currentView) {
              case 'month':
                return (
                  <MonthView
                    currentDate={currentDate}
                    meetings={meetings}
                    currentTimezone={currentTimezone}
                    onMeetingClick={onMeetingClick}
                    setShowEditor={setShowEditor}
                    setEditorPosition={setEditorPosition}
                    setDraftMeeting={setDraftMeeting}
                    showEditor={showEditor}
                    draftMeeting={draftMeeting}
                  />
                );
              case 'week':
                return (
                  <WeekView
                    currentDate={currentDate}
                    meetings={meetings}
                    currentTimezone={currentTimezone}
                    onCreateMeeting={onCreateMeeting}
                    onMeetingClick={onMeetingClick}
                    showEditor={showEditor}
                    editorPosition={editorPosition}
                    setShowEditor={setShowEditor}
                    setEditorPosition={setEditorPosition}
                    setDraftMeeting={setDraftMeeting}
                    draftMeeting={draftMeeting}
                  />
                );
              case 'agenda':
                return (
                  <AgendaView meetings={meetings} currentDate={currentDate} onMeetingClick={onMeetingClick} />
                );
              default:
                return null;
            }
          })()}
        </div>
      </div>

      <AddEventModal isOpen={isAddEventModalOpen} onClose={() => setIsAddEventModalOpen(false)} />

      <MeetingDetailsModal
        meeting={selectedMeeting}
        onClose={() => setSelectedMeeting(null)}
        onEditMeeting={onEditMeeting}
        onDeleteMeeting={onDeleteMeeting}
      />

      {showEditor && draftMeeting && (
        <InlineMeetingEditor
          meeting={draftMeeting}
          startTime={draftMeeting.startTime}
          endTime={draftMeeting.endTime}
          position={editorPosition}
          onSave={(title) => {
            if (onCreateMeeting) {
              onCreateMeeting(draftMeeting.startTime, draftMeeting.endTime, draftMeeting.dayDate);
            }
            setShowEditor(false);
            setDraftMeeting(null);
          }}
          onCancel={() => {
            setShowEditor(false);
            setDraftMeeting(null);
          }}
        />
      )}
    </div>
  );
}

export default Calendar;
