import { create } from 'zustand';
import axios from 'axios';
import { Meeting, UserEmail } from '../components/Calendar/types';

interface CalendarStore {
  selectedCalendars: number[];
  currentTimezone: string;
  currentDate: Date;
  meetings: Meeting[];
  calendars: UserEmail[];
  isLoading: boolean;

  // Actions
  toggleCalendar: (calendarId: number) => void;
  setCurrentDate: (date: Date) => void;
  setCurrentTimezone: (timezone: string) => void;
  fetchCalendars: () => Promise<void>;
  fetchMeetings: () => Promise<void>;
}

export const useCalendarStore = create<CalendarStore>((set, get) => ({
  selectedCalendars: [],
  currentTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  currentDate: new Date(),
  meetings: [],
  calendars: [],
  isLoading: false,

  toggleCalendar: (calendarId) => {
    set((state) => ({
      selectedCalendars: state.selectedCalendars.includes(calendarId)
        ? state.selectedCalendars.filter((id) => id !== calendarId)
        : [...state.selectedCalendars, calendarId],
    }));
    get().fetchMeetings();
  },

  setCurrentDate: (date) => {
    set({ currentDate: date });
    get().fetchMeetings();
  },

  setCurrentTimezone: (timezone) => {
    set({ currentTimezone: timezone });
    get().fetchMeetings();
  },

  fetchCalendars: async () => {
    try {
      const response = await axios.get('/calendars');
      set({ calendars: response.data.calendars });
    } catch (error) {
      console.error('Failed to fetch calendars:', error);
    }
  },

  fetchMeetings: async () => {
    const { currentDate, selectedCalendars, currentTimezone } = get();
    set({ isLoading: true });

    try {
      const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

      const viewStartDate = new Date(startDate);
      viewStartDate.setDate(viewStartDate.getDate() - viewStartDate.getDay());

      const viewEndDate = new Date(endDate);
      viewEndDate.setDate(endDate.getDate() + (6 - endDate.getDay()));

      const response = await axios.get('/meetings', {
        params: {
          start_date: viewStartDate.toISOString(),
          end_date: viewEndDate.toISOString(),
          calendar_ids: selectedCalendars,
          timezone: currentTimezone,
        },
      });

      set({ meetings: response.data.meetings });
    } catch (error) {
      console.error('Failed to fetch meetings:', error);
    } finally {
      set({ isLoading: false });
    }
  },
}));
