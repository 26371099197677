import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faUsers,
  faVideo,
  faLocationDot,
  faAlignLeft,
  faCalendarDay,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

interface AddEventModalProps {
  isOpen: boolean;
  onClose: () => void;
  onEventCreated?: (meeting: any) => void;
}

type EventType = 'Event' | 'Task' | 'Appointment';

export const AddEventModal: React.FC<AddEventModalProps> = ({ isOpen, onClose, onEventCreated }) => {
  const [eventType, setEventType] = useState<EventType>('Event');
  const [title, setTitle] = useState('');
  const [busy, setBusy] = useState(true);
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [guests, setGuests] = useState<Array<{ email: string }>>([]);

  if (!isOpen) return null;

  const handleSubmit = async () => {
    try {
      const response = await axios.post('/meetings', {
        meeting: {
          name: title,
          starts_at: startDate,
          ends_at: endDate,
          description,
          location,
          all_day: false,
        },
        meeting_users: guests.map((guest) => ({
          email: guest.email,
          response_status: 'pending',
          organizer: false,
          is_self: false,
        })),
        send_notifications: true,
      });

      if (response.data.success) {
        onEventCreated?.(response.data.meeting);
        onClose();
      }
    } catch (error) {
      console.error('Failed to create meeting:', error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto dark:text-white">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-gray-900 bg-opacity-95 transition-opacity" onClick={onClose} />

        <div
          className="relative bg-white dark:bg-gray-800 rounded-lg w-full max-w-2xl"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header */}
          <div className="flex items-center justify-between p-4 border-b dark:border-gray-700">
            <input
              type="text"
              placeholder="Add title"
              className="text-xl font-normal bg-transparent border-none focus:outline-none focus:ring-0 w-full"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              autoFocus
            />
            <button onClick={onClose} className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full">
              <FontAwesomeIcon icon={faTimes} className="w-6 h-6" />
            </button>
          </div>

          {/* Event Type Selector */}
          <div className="p-4 flex gap-2">
            {(['Event', 'Task', 'Appointment'] as EventType[]).map((type) => (
              <button
                key={type}
                onClick={() => setEventType(type)}
                className={`px-4 py-2 rounded-lg ${
                  eventType === type
                    ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200'
                    : 'hover:bg-gray-100 dark:hover:bg-gray-700'
                }`}
              >
                {type}
              </button>
            ))}
          </div>

          {/* Main Content */}
          <div className="p-4 space-y-4">
            {/* Time Selection */}
            <div className="flex items-center space-x-4">
              <FontAwesomeIcon icon={faClock} className="w-6 h-6 text-gray-500" />
              <div className="flex-1">
                <input
                  type="datetime-local"
                  className="bg-transparent border-none focus:outline-none focus:ring-0"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <span className="mx-2">-</span>
                <input
                  type="datetime-local"
                  className="bg-transparent border-none focus:outline-none focus:ring-0"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>

            {/* Guests */}
            <div className="flex items-center space-x-4 py-2 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-lg px-2">
              <FontAwesomeIcon icon={faUsers} className="w-6 h-6 text-gray-500" />
              <input
                type="text"
                placeholder="Add guests (comma-separated emails)"
                className="flex-1 bg-transparent border-none focus:outline-none focus:ring-0"
                onChange={(e) =>
                  setGuests(e.target.value.split(',').map((email) => ({ email: email.trim() })))
                }
              />
            </div>

            {/* Google Meet */}
            <div className="flex items-center space-x-4 py-2 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-lg px-2">
              <FontAwesomeIcon icon={faVideo} className="w-6 h-6 text-gray-500" />
              <span className="text-blue-600">Add Google Meet video conferencing</span>
            </div>

            {/* Location */}
            <div className="flex items-center space-x-4 py-2 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-lg px-2">
              <FontAwesomeIcon icon={faLocationDot} className="w-6 h-6 text-gray-500" />
              <input
                type="text"
                placeholder="Add location"
                className="flex-1 bg-transparent border-none focus:outline-none focus:ring-0"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>

            {/* Description */}
            <div className="flex items-center space-x-4 py-2 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-lg px-2">
              <FontAwesomeIcon icon={faAlignLeft} className="w-6 h-6 text-gray-500" />
              <input
                type="text"
                placeholder="Add description or attachments"
                className="flex-1 bg-transparent border-none focus:outline-none focus:ring-0"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            {/* Calendar Selection */}
            <div className="flex items-center justify-between py-2 px-2">
              <div className="flex items-center space-x-4">
                <FontAwesomeIcon icon={faCalendarDay} className="w-6 h-6 text-gray-500" />
                <span>Personal</span>
              </div>
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-500">{busy ? 'Busy' : 'Free'}</span>
                <button
                  onClick={() => setBusy(!busy)}
                  className={`w-10 h-6 rounded-full p-1 transition-colors duration-200 ease-in-out ${
                    busy ? 'bg-blue-600' : 'bg-gray-200'
                  }`}
                >
                  <div
                    className={`w-4 h-4 rounded-full bg-white transform transition-transform duration-200 ease-in-out ${
                      busy ? 'translate-x-4' : 'translate-x-0'
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="flex justify-between items-center p-4 border-t dark:border-gray-700">
            <button className="text-blue-600 hover:bg-blue-50 dark:hover:bg-blue-900/50 px-4 py-2 rounded">
              More options
            </button>
            <div className="space-x-2">
              <button
                onClick={onClose}
                className="px-4 py-2 text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
