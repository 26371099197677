import React, { useState, useCallback } from 'react';
import { Meeting } from '../types';
import { getWeekDays, weekDays } from '../utils';
import { WeekMeeting } from './WeekMeeting';

interface WeekViewProps {
  currentDate: Date;
  meetings: Meeting[];
  currentTimezone: string;
  onCreateMeeting?: (startTime: Date, endTime: Date, dayDate: Date) => void;
  onMeetingClick: (meeting: Meeting) => void;
  showEditor: boolean;
  editorPosition: { top: number; left: number };
  setShowEditor: (show: boolean) => void;
  setEditorPosition: (position: { top: number; left: number }) => void;
  setDraftMeeting: (
    meeting: {
      startTime: Date;
      endTime: Date;
      top: number;
      height: number;
      dayDate: Date;
    } | null,
  ) => void;
  draftMeeting: {
    id?: number;
    startTime: Date;
    endTime: Date;
    top: number;
    height: number;
    dayDate: Date;
  } | null;
}

const HOURS = Array.from({ length: 24 }, (_, i) => i);
const CELL_HEIGHT = 60; // height in pixels for each hour cell

const getTimeFromY = (y: number): Date => {
  const hour = Math.floor(y / CELL_HEIGHT);
  const minute = Math.floor((y % CELL_HEIGHT) / (CELL_HEIGHT / 60));
  const date = new Date();
  date.setHours(hour, minute, 0, 0);
  return date;
};

export const WeekView: React.FC<WeekViewProps> = ({
  currentDate,
  meetings,
  currentTimezone,
  onCreateMeeting,
  onMeetingClick,
  showEditor,
  editorPosition,
  setShowEditor,
  setEditorPosition,
  setDraftMeeting,
  draftMeeting,
}) => {
  const [currentTime, setCurrentTime] = React.useState(new Date());
  const [dragStart, setDragStart] = useState<{ y: number; date: Date } | null>(null);
  const [dragEnd, setDragEnd] = useState<number | null>(null);

  React.useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 60000); // Update every minute
    return () => clearInterval(timer);
  }, []);

  React.useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (showEditor && !(e.target as Element).closest('.inline-event-editor')) {
        setShowEditor(false);
        setDraftMeeting(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showEditor]);

  React.useEffect(() => {
    // Wait for next render cycle to ensure DOM is ready
    const timer = setTimeout(() => {
      const container = document.querySelector('.week-view-grid-container');
      if (container) {
        // Scroll to show business hours (e.g., 9 AM) instead of scrolling to bottom
        const businessHourOffset = 9 * CELL_HEIGHT; // 9 AM
        container.scrollTop = businessHourOffset;
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [meetings]); // Add meetings as dependency to re-run when meetings change

  const handleDragEnd = useCallback(() => {
    if (dragStart) {
      const startY = dragStart.y;
      const endY = dragEnd === null ? startY + CELL_HEIGHT : dragEnd;

      const startTime = getTimeFromY(Math.min(startY, endY));
      const endTime = getTimeFromY(Math.max(startY, endY));

      startTime.setFullYear(
        dragStart.date.getFullYear(),
        dragStart.date.getMonth(),
        dragStart.date.getDate(),
      );
      endTime.setFullYear(dragStart.date.getFullYear(), dragStart.date.getMonth(), dragStart.date.getDate());

      const top = Math.min(startY, endY);
      const height = Math.abs(endY - startY);

      setDraftMeeting({
        startTime,
        endTime,
        top,
        height,
        dayDate: dragStart.date,
      });

      // Get the container element's position
      const leftSidebar = document.querySelector('.left-sidebar-container');
      const containerElement = document.querySelector('.week-view-grid-container');
      const timeLegendContainer = document.querySelector('.time-legend-container');
      const containerRect = containerElement?.getBoundingClientRect() || {
        top: 0,
        left: 0,
        width: 0, // Add width property
        height: 0, // Add height property for completeness
      };
      const timeLegendWidth = timeLegendContainer?.getBoundingClientRect().width || 0;

      if (!timeLegendWidth || !containerRect.width) {
        return;
      }

      const dayWidth = (containerRect.width - timeLegendWidth) / 7;

      setEditorPosition({
        top: dragStart.y + containerRect.top - (containerElement?.scrollTop || 0),
        left: dayWidth * dragStart.date.getDay() - 4,
      });

      setShowEditor(true);
    }
    setDragStart(null);
    setDragEnd(null);
  }, [dragStart, dragEnd]);

  const DraftMeeting = useCallback(() => {
    if (!draftMeeting) return null;

    const formatTime = (date: Date) => {
      return date.toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      });
    };

    const handleClick = (e: React.MouseEvent) => {
      console.log('4. DraftEvent clicked');
      e.stopPropagation();
      const rect = e.currentTarget.getBoundingClientRect();
      console.log('5. Click position', {
        rect,
        top: rect.top,
        left: rect.right + 8,
      });
      setEditorPosition({
        top: rect.top,
        left: rect.right + 8,
      });
      setShowEditor(true);
    };

    return (
      <div
        className="absolute left-1 right-1 bg-blue-500 text-white rounded p-1 text-sm cursor-pointer hover:opacity-90 transition-opacity z-20"
        style={{
          top: `${draftMeeting.top}px`,
          height: `${draftMeeting.height}px`,
        }}
        onClick={handleClick}
      >
        <div className="font-medium">(No title)</div>
        <div className="text-xs opacity-90">
          {formatTime(draftMeeting.startTime)} - {formatTime(draftMeeting.endTime)}
        </div>
      </div>
    );
  }, [draftMeeting]);

  return (
    <div className="flex flex-col h-full overflow-scroll">
      {/* Fixed header */}
      <div className="sticky top-0 z-30 grid grid-cols-[auto_repeat(7,1fr)] gap-px bg-gray-200 border-b dark:bg-gray-700 dark:border-gray-600">
        <div className="w-16 dark:bg-gray-800 bg-white" />
        {getWeekDays(currentDate).map((date) => (
          <div
            key={date.toISOString()}
            className="bg-white p-2 text-center text-sm font-medium text-gray-500 dark:bg-gray-800 dark:text-gray-400"
          >
            <div>{weekDays[date.getDay()]}</div>
            <div
              className={`mt-1 ${
                date.toDateString() === new Date().toDateString()
                  ? 'bg-blue-500 text-white dark:bg-blue-600 w-6 h-6 rounded-full flex items-center justify-center mx-auto'
                  : ''
              }`}
            >
              {date.getDate()}
            </div>
          </div>
        ))}
      </div>

      {/* Scrollable content */}
      <div className="week-view-grid-container flex-grow grid grid-cols-[auto_1fr] gap-px bg-gray-200 dark:bg-gray-700 relative overflow-auto">
        <div className="time-legend-container w-16">
          {HOURS.map((hour) => (
            <div
              key={hour}
              className="text-sm text-gray-500 dark:text-gray-400 flex items-center justify-center dark:bg-gray-800 bg-white"
              style={{ height: `${CELL_HEIGHT}px`, lineHeight: `${CELL_HEIGHT}px` }}
            >
              {hour === 0 ? '12 AM' : hour < 12 ? `${hour} AM` : hour === 12 ? '12 PM' : `${hour - 12} PM`}
            </div>
          ))}
        </div>

        <div className="grid grid-cols-7 gap-px relative">
          <div className="absolute inset-0 grid grid-rows-24" style={{ pointerEvents: 'none', zIndex: 10 }}>
            {HOURS.map((hour) => (
              <div
                key={hour}
                className="border-t border-gray-300 dark:border-gray-600"
                style={{ height: `${CELL_HEIGHT}px` }}
              />
            ))}
          </div>

          <div
            className="absolute left-0 right-0 flex items-center z-20"
            style={{
              top: `${(currentTime.getHours() * 60 + currentTime.getMinutes()) * (CELL_HEIGHT / 60)}px`,
              transform: 'translateY(-50%)',
            }}
          >
            <div className="w-2 h-2 rounded-full bg-red-500 -ml-1" />
            <div className="flex-1 h-px bg-red-500" />
          </div>

          {getWeekDays(currentDate).map((date) => (
            <div
              key={date.toISOString()}
              className="relative bg-white dark:bg-gray-800"
              style={{ minHeight: `${24 * CELL_HEIGHT}px` }}
              onMouseDown={(e) => {
                const isMeeting = (e.target as Element).closest('.calendar-meeting');
                if (showEditor || isMeeting) {
                  setShowEditor(false);
                  setDraftMeeting(null);
                  return;
                }

                const rect = e.currentTarget.getBoundingClientRect();
                const y = e.clientY - rect.top + e.currentTarget.parentElement?.scrollTop!;
                setDragStart({ y, date });
              }}
              onMouseMove={(e) => {
                if (dragStart) {
                  const rect = e.currentTarget.getBoundingClientRect();
                  const y = e.clientY - rect.top + e.currentTarget.parentElement?.scrollTop!;
                  setDragEnd(y);
                }
              }}
              onMouseUp={handleDragEnd}
              onMouseLeave={handleDragEnd}
            >
              {dragStart && date.toDateString() === dragStart.date.toDateString() && dragEnd !== null && (
                <div
                  className="absolute left-1 right-1 bg-blue-200 opacity-50 dark:bg-blue-800"
                  style={{
                    top: `${Math.min(dragStart.y, dragEnd)}px`,
                    height: `${Math.abs(dragEnd - dragStart.y)}px`,
                  }}
                />
              )}

              {draftMeeting &&
                date.toDateString() === draftMeeting.dayDate.toDateString() &&
                !draftMeeting.id && <DraftMeeting />}

              {meetings
                .filter((meeting) => new Date(meeting.starts_at).toDateString() === date.toDateString())
                .map((meeting) => (
                  <WeekMeeting
                    key={meeting.id}
                    meeting={meeting}
                    currentTimezone={currentTimezone}
                    onMeetingClick={onMeetingClick}
                    cellHeight={CELL_HEIGHT}
                  />
                ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
