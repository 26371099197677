import React from 'react';
import { Meeting } from '../types';
import { formatTime } from '../utils';

interface AgendaViewProps {
  meetings: Meeting[];
  currentDate: Date;
  onMeetingClick: (meeting: Meeting) => void;
}

export const AgendaView: React.FC<AgendaViewProps> = ({ meetings, currentDate, onMeetingClick }) => {
  // Filter meetings for the current month
  const filteredMeetings = meetings.filter((meeting) => {
    const meetingDate = new Date(meeting.starts_at);

    // Check if the date is valid before comparing
    if (isNaN(meetingDate.getTime())) {
      console.error('Invalid meeting date:', meeting);
      return false;
    }

    return (
      meetingDate.getMonth() === currentDate.getMonth() &&
      meetingDate.getFullYear() === currentDate.getFullYear()
    );
  });

  // Sort meetings by start time
  const sortedMeetings = [...filteredMeetings].sort(
    (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
  );

  // Group meetings by date
  const groupedMeetings = sortedMeetings.reduce((groups, meeting) => {
    const date = new Date(meeting.starts_at).toLocaleDateString('default', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });

    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(meeting);
    return groups;
  }, {} as Record<string, Meeting[]>);

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="max-w-3xl mx-auto p-4">
        {Object.entries(groupedMeetings).map(([date, dayMeetings]) => (
          <div key={date} className="mb-8">
            <h2 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">{date}</h2>
            {dayMeetings.map((meeting) => {
              const startTime = new Date(meeting.starts_at);
              const endTime = new Date(meeting.ends_at);

              return (
                <div
                  key={meeting.id}
                  onClick={() => onMeetingClick(meeting)}
                  className="mb-4 p-4 bg-white rounded-lg shadow cursor-pointer hover:shadow-md dark:bg-gray-800 dark:border-gray-700"
                >
                  <div className="flex items-start">
                    <div className="w-32 flex-shrink-0 text-sm text-gray-500 dark:text-gray-400">
                      {formatTime(startTime, 'friendly')} - {formatTime(endTime, 'friendly')}
                    </div>
                    <div className="flex-1">
                      <h3 className="font-medium text-gray-900 dark:text-white">{meeting.name}</h3>
                      {meeting.color && (
                        <div
                          className="w-3 h-3 rounded-full mt-1"
                          style={{ backgroundColor: meeting.color }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ))}
        {sortedMeetings.length === 0 && (
          <div className="text-center text-gray-500 dark:text-gray-400 py-8">
            No meetings scheduled for this month
          </div>
        )}
      </div>
    </div>
  );
};
