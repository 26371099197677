import React from 'react';
import { Meeting } from '../types';
import { weekDays, getCalendarDays } from '../utils';

interface MonthViewProps {
  currentDate: Date;
  meetings: Meeting[];
  currentTimezone: string;
  onMeetingClick: (meeting: Meeting) => void;
  setShowEditor: (show: boolean) => void;
  setEditorPosition: (position: { top: number; left: number }) => void;
  setDraftMeeting: (
    meeting: {
      startTime: Date;
      endTime: Date;
      top: number;
      height: number;
      dayDate: Date;
    } | null,
  ) => void;
  showEditor: boolean;
  draftMeeting: {
    id?: number;
    startTime: Date;
    endTime: Date;
    top: number;
    height: number;
    dayDate: Date;
  } | null;
}

export const MonthView: React.FC<MonthViewProps> = ({
  currentDate,
  meetings,
  currentTimezone,
  onMeetingClick,
  setShowEditor,
  setEditorPosition,
  setDraftMeeting,
  showEditor,
  draftMeeting,
}) => {
  const handleDateClick = (date: Date, event: React.MouseEvent) => {
    if (showEditor) {
      setShowEditor(false);
      setDraftMeeting(null);
      return;
    }

    const rect = (event.currentTarget as Element).getBoundingClientRect();
    const dayWidth = rect.width;

    const startTime = new Date(date);
    startTime.setHours(9, 0, 0, 0);
    const endTime = new Date(startTime);
    endTime.setHours(10, 0, 0, 0);

    setDraftMeeting({
      startTime,
      endTime,
      top: rect.top,
      height: 60,
      dayDate: date,
    });

    setEditorPosition({
      top: rect.top,
      left: rect.right - dayWidth - 330,
    });

    setShowEditor(true);
  };

  const DraftMeeting = () => {
    if (!draftMeeting) return null;

    const formatTime = (date: Date) => {
      return date.toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      });
    };

    return (
      <div className="mt-1">
        <div className="bg-blue-500 text-white rounded p-1 text-sm">
          <div className="font-medium">(No title)</div>
          <div className="text-xs opacity-90">
            {formatTime(draftMeeting.startTime)} - {formatTime(draftMeeting.endTime)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="grid grid-cols-7 gap-px bg-gray-200 border-b dark:bg-gray-700 dark:border-gray-600">
        {weekDays.map((day) => (
          <div
            key={day}
            className="bg-white p-2 text-center text-sm font-medium text-gray-500 dark:bg-gray-800 dark:text-gray-400"
          >
            {day}
          </div>
        ))}
      </div>
      <div className="flex-grow border-b border-gray-200 grid grid-cols-7 gap-px bg-gray-200 dark:bg-gray-700 dark:border-gray-600">
        {getCalendarDays(currentDate).map((date) => {
          const isToday = date.toDateString() === new Date().toDateString();
          const isDraftDate = draftMeeting && date.toDateString() === draftMeeting.dayDate.toDateString();

          return (
            <div
              key={date.toISOString()}
              className={`bg-white p-2 dark:bg-gray-800 min-h-[120px] cursor-pointer ${
                date.getMonth() !== currentDate.getMonth() ? 'opacity-50' : ''
              }`}
              onClick={(e) => handleDateClick(date, e)}
            >
              <div
                className={`text-sm ${
                  isToday
                    ? 'bg-blue-500 text-white dark:bg-blue-600 w-6 h-6 rounded-full flex items-center justify-center'
                    : 'text-gray-500 dark:text-gray-400'
                }`}
              >
                {date.getDate()}
              </div>
              {isDraftDate && !draftMeeting.id && <DraftMeeting />}
              {meetings
                .filter((meeting) => new Date(meeting.starts_at).toDateString() === date.toDateString())
                .sort((a, b) => new Date(a.starts_at).getTime() - new Date(b.starts_at).getTime())
                .map((meeting) => (
                  <div
                    key={meeting.id}
                    className="mt-1 cursor-pointer hover:opacity-80"
                    onClick={(e) => {
                      e.stopPropagation();
                      onMeetingClick(meeting);
                    }}
                    id={`meeting-${meeting.id}`}
                  >
                    <div className="bg-blue-100 text-blue-700 rounded p-1 text-sm dark:bg-blue-900 dark:text-blue-200">
                      {meeting.name}
                      <div className="text-xs text-gray-600 dark:text-gray-400">
                        {new Date(meeting.starts_at).toLocaleTimeString([], {
                          hour: 'numeric',
                          minute: '2-digit',
                          hour12: true,
                          timeZone: currentTimezone,
                        })}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          );
        })}
      </div>
    </>
  );
};
