import React from 'react';
import { CalendarView } from '../types';

interface CalendarHeaderProps {
  currentDate: Date;
  currentView: CalendarView;
  currentTimezone: string;
  setCurrentView: (view: CalendarView) => void;
  goToToday: () => void;
  goToPrevious: () => void;
  goToNext: () => void;
}

export const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  currentDate,
  currentView,
  currentTimezone,
  setCurrentView,
  goToToday,
  goToPrevious,
  goToNext,
}) => {
  return (
    <div className="flex items-center justify-between p-4 border-b dark:border-gray-700">
      <div className="flex items-center space-x-4">
        <h2 className="text-xl font-semibold dark:text-white" style={{ width: 200 }}>
          {currentDate.toLocaleString('default', {
            month: 'long',
            year: 'numeric',
            ...(currentView === 'week' && { day: 'numeric' }),
          })}
        </h2>
        <div className="flex rounded-md shadow-sm">
          <button
            onClick={() => setCurrentView('month')}
            className={`px-4 py-2 text-sm font-medium border ${
              currentView === 'month'
                ? 'bg-blue-50 border-blue-500 text-blue-600 dark:bg-blue-900/30 dark:border-blue-400 dark:text-blue-300'
                : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600'
            } rounded-l-md`}
          >
            Month
          </button>
          <button
            onClick={() => setCurrentView('week')}
            className={`px-4 py-2 text-sm font-medium border-t border-b border-r ${
              currentView === 'week'
                ? 'bg-blue-50 border-blue-500 text-blue-600 dark:bg-blue-900/30 dark:border-blue-400 dark:text-blue-300 border-l'
                : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600'
            } rounded-r-md`}
          >
            Week
          </button>
          {/* <button
            onClick={() => setCurrentView('agenda')}
            className={`px-4 py-2 text-sm font-medium border-t border-b border-r ${
              currentView === 'agenda'
                ? 'bg-blue-50 border-blue-500 text-blue-600 dark:bg-blue-900/30 dark:border-blue-400 dark:text-blue-300 border-l'
                : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600'
            } rounded-r-md`}
          >
            Agenda
          </button> */}
        </div>
      </div>
      <div className="flex space-x-4 items-center">
        <div className="relative">
          <button className="px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 dark:hover:bg-gray-600">
            {currentTimezone}
            <svg className="w-4 h-4 ml-1 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path d="M19 9l-7 7-7-7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
          </button>
        </div>
        <button
          onClick={goToToday}
          className="px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 dark:hover:bg-gray-600"
        >
          Today
        </button>
        <div className="flex space-x-0">
          <button
            onClick={goToPrevious}
            className="p-2 text-gray-600 bg-white border border-gray-300 border-r-0 rounded-l-md hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 dark:hover:bg-gray-600"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path d="M15 19l-7-7 7-7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
          </button>
          <button
            onClick={goToNext}
            className="p-2 text-gray-600 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 dark:hover:bg-gray-600"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path d="M9 5l7 7-7 7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
