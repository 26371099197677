import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useCalendarStore } from '../../../stores/calendarStore';
import { Meeting } from '../types';

interface InlineMeetingEditorProps {
  startTime: Date;
  endTime: Date;
  onSave: (title: string) => void;
  onCancel: () => void;
  position: { top: number; left: number };
  meeting?: Meeting;
}

export const InlineMeetingEditor: React.FC<InlineMeetingEditorProps> = ({
  meeting,
  startTime,
  endTime,
  onSave,
  onCancel,
  position,
}) => {
  console.log('meeting', JSON.stringify(meeting, null, 2));

  const [title, setTitle] = useState(meeting?.name || '');
  const [selectedCalendarId, setSelectedCalendarId] = useState<number | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { calendars, fetchMeetings } = useCalendarStore();
  const [meetingStartTime, setMeetingStartTime] = useState(startTime);
  const [meetingEndTime, setMeetingEndTime] = useState(endTime);

  useEffect(() => {
    inputRef.current?.focus();
    if (calendars.length > 0 && !selectedCalendarId) {
      setSelectedCalendarId(calendars[0].id);
    }
  }, [calendars]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSave();
    } else if (e.key === 'Escape') {
      onCancel();
    }
  };

  const handleSave = async () => {
    if (!selectedCalendarId) {
      alert('Please select a calendar');
      return;
    }

    setIsSaving(true);

    try {
      const meetingData = {
        meeting: {
          name: title || '(No title)',
          starts_at: meetingStartTime.toISOString(),
          ends_at: meetingEndTime.toISOString(),
          calendar_id: selectedCalendarId,
        },
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (meeting?.id) {
        await axios.put(`/meetings/${meeting.id}`, meetingData);
      } else {
        await axios.post('/meetings', meetingData);
      }

      // Fetch updated meetings after successful creation
      await fetchMeetings();

      onSave(title);
    } catch (error) {
      console.error('Failed to create meeting:', error);
      alert('Failed to create meeting. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div
      className="absolute bg-white dark:bg-gray-800 rounded-lg shadow-2xl border dark:border-gray-700 p-4"
      style={{
        top: position.top,
        left: position.left,
        width: '330px',
        zIndex: 100,
      }}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <div className="flex items-center gap-2">
        <input
          ref={inputRef}
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Add title"
          className="flex-1 bg-transparent border-none focus:outline-none focus:ring-0 text-black dark:text-white"
          autoFocus
        />
        <button onClick={onCancel} className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded">
          <i className="fa-solid fa-xmark text-gray-500 dark:text-gray-400" />
        </button>
      </div>
      <div className="mt-2 flex flex-row items-center">
        <span className="pr-2">
          <i className="fa-solid fa-calendar-days text-gray-500 dark:text-gray-400" />
        </span>
        <div className="text-sm text-gray-500 dark:text-gray-400">
          <input
            type="date"
            value={meetingStartTime.toISOString().split('T')[0]}
            onChange={(e) => {
              const newDate = new Date(e.target.value);
              const newStartTime = new Date(meetingStartTime);
              newStartTime.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
              setMeetingStartTime(newStartTime);
            }}
            className="bg-transparent border dark:border-gray-700 rounded px-1 text-sm text-gray-500 dark:text-white"
          />{' '}
          -{' '}
          <input
            type="date"
            value={meetingEndTime.toISOString().split('T')[0]}
            onChange={(e) => {
              const newDate = new Date(e.target.value);
              const newEndTime = new Date(meetingEndTime);
              newEndTime.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
              setMeetingEndTime(newEndTime);
            }}
            className="bg-transparent border dark:border-gray-700 rounded px-1 text-sm text-gray-500 dark:text-white"
          />
        </div>
      </div>
      <div className="mt-2 flex flex-row items-center">
        <span className="pr-2">
          <i className="fa-regular fa-clock text-gray-500 dark:text-white" />
        </span>
        <div className="text-sm text-gray-500 dark:text-gray-400">
          <input
            type="time"
            value={meetingStartTime.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })}
            onChange={(e) => {
              const [hours, minutes] = e.target.value.split(':');
              const newStartTime = new Date(meetingStartTime);
              newStartTime.setHours(parseInt(hours), parseInt(minutes));
              setMeetingStartTime(newStartTime);
            }}
            className="bg-transparent border dark:border-gray-700 rounded px-1 text-sm text-gray-500 dark:text-white"
          />{' '}
          -{' '}
          <input
            type="time"
            value={meetingEndTime.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })}
            onChange={(e) => {
              const [hours, minutes] = e.target.value.split(':');
              const newEndTime = new Date(meetingEndTime);
              newEndTime.setHours(parseInt(hours), parseInt(minutes));
              setMeetingEndTime(newEndTime);
            }}
            className="bg-transparent border dark:border-gray-700 rounded px-1 text-sm text-gray-500 dark:text-white"
          />
        </div>
      </div>
      <div className="mt-2 flex flex-row items-center">
        <span className="pr-2">
          <i className="fa-solid fa-repeat text-gray-500 dark:text-gray-400" />
        </span>
        <select
          className="w-full p-1 text-sm rounded border dark:border-gray-700 bg-transparent text-black dark:text-white"
          defaultValue="does_not_repeat"
        >
          <option value="does_not_repeat">Does not repeat</option>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </select>
      </div>
      <div className="mt-2 flex flex-row items-center">
        <span className="pr-2">
          <i className="fa-solid fa-calendar-days text-gray-500 dark:text-gray-400" />
        </span>
        <select
          value={selectedCalendarId || ''}
          onChange={(e) => setSelectedCalendarId(Number(e.target.value))}
          className="w-full p-1 text-sm rounded border dark:border-gray-700 bg-transparent text-black dark:text-white"
        >
          {calendars.map((calendar) => (
            <option key={calendar.id} value={calendar.id}>
              {calendar.email}
            </option>
          ))}
        </select>
      </div>
      <div className="flex mt-4 justify-end">
        <button
          onClick={handleSave}
          disabled={isSaving || !selectedCalendarId}
          className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isSaving ? 'Saving...' : 'Save'}
        </button>
      </div>
    </div>
  );
};
