import React from 'react';
import { UserEmail } from '../types';

interface SidebarProps {
  calendars: UserEmail[];
  selectedCalendars: number[];
  onCalendarToggle: (id: number) => void;
  onOpenAddEventModal: () => void;
}

export const Sidebar: React.FC<SidebarProps> = ({
  calendars,
  selectedCalendars,
  onCalendarToggle,
  onOpenAddEventModal,
}) => {
  return (
    <div className="left-sidebar-container h-full w-full border-b border-r dark:border-gray-700">
      <div className="p-4">
        <button
          onClick={onOpenAddEventModal}
          className="w-full mb-6 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600"
        >
          Add Event
        </button>
        <div className="text-sm font-semibold text-gray-800 dark:text-gray-200">Calendars</div>
        <div className="mt-4">
          {calendars.map((calendar) => (
            <div key={calendar.id} className="flex items-center my-2 text-sm space-x-2">
              <input
                type="checkbox"
                id={`calendar-${calendar.id}`}
                name="calendar_ids[]"
                value={calendar.id}
                checked={selectedCalendars.includes(calendar.id)}
                onChange={() => onCalendarToggle(calendar.id)}
              />
              <label htmlFor={`calendar-${calendar.id}`} className="text-gray-700 dark:text-gray-200">
                {calendar.email}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
