import { useEffect } from 'react';
import { useCalendarStore } from '../../../stores/calendarStore';

export const useCalendar = () => {
  const {
    selectedCalendars,
    currentTimezone,
    currentDate,
    meetings,
    isLoading,
    calendars,
    toggleCalendar: handleCalendarToggle,
    setCurrentDate,
    setCurrentTimezone,
    fetchCalendars,
    fetchMeetings,
  } = useCalendarStore();

  useEffect(() => {
    fetchCalendars();
    fetchMeetings();
  }, []);

  return {
    selectedCalendars,
    currentTimezone,
    currentDate,
    meetings,
    isLoading,
    calendars,
    handleCalendarToggle,
    setCurrentDate,
    setCurrentTimezone,
    fetchMeetings,
  };
};
