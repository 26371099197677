import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Layout from '../layout/Layout';
import Calendar from '../components/Calendar';

export default (
  <Layout>
    <Routes>
      <Route path="/" element={<Calendar />} />
      <Route path="/calendar" element={<Calendar />} />
    </Routes>
  </Layout>
);
