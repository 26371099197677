import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['currentTimezone', 'loadingIndicator'];

  connect() {
    if (!document.cookie.includes('timezone')) {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      this.setTimezone(timeZone);
    }
    console.log('HELLOCalendar!!s controller connected');
    document.addEventListener('keydown', this.handleKeyPress.bind(this));
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeyPress.bind(this));
  }

  handleKeyPress(event) {
    if (event.key.toLowerCase() === 'r') {
      console.log('handleKeyPress', event);
      this.refresh();
    }
  }

  changeTimezone({
    target: {
      dataset: { timezone },
    },
  }) {
    this.setTimezone(timezone);
  }

  goToToday() {
    this.element.querySelector('form')?.submit();
  }

  async setTimezone(timezone) {
    if (this.hasLoadingIndicatorTarget) {
      this.loadingIndicatorTarget.classList.remove('hidden');
    }

    document.cookie = `timezone=${timezone};path=/;`;

    try {
      window.location.reload();
    } finally {
      if (this.hasLoadingIndicatorTarget) {
        this.loadingIndicatorTarget.classList.add('hidden');
      }
    }
  }

  async refresh() {
    this.loadingIndicatorTarget.classList.remove('hidden');

    console.log('refresh');

    try {
      const response = await fetch(window.location.href);
      const html = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');

      // Find the calendar grid and replace it
      const newCalendarGrid = doc.querySelector('.flex-grow.border-b.grid.grid-cols-7');
      const currentCalendarGrid = document.querySelector('.flex-grow.border-b.grid.grid-cols-7');

      if (newCalendarGrid && currentCalendarGrid) {
        currentCalendarGrid.innerHTML = newCalendarGrid.innerHTML;
      }
    } catch (error) {
      console.error('Failed to refresh calendar:', error);
    } finally {
      this.loadingIndicatorTarget.classList.add('hidden');
    }
  }
}
