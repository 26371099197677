import React from 'react';
import { Meeting } from '@/types';
import DOMPurify from 'dompurify';

interface MeetingDetailsModalProps {
  meeting: Meeting | null;
  onClose: () => void;
  onEditMeeting: (meeting: Meeting) => void;
  onDeleteMeeting: (meeting: Meeting) => void;
}

export const MeetingDetailsModal: React.FC<MeetingDetailsModalProps> = ({
  meeting,
  onClose,
  onEditMeeting,
  onDeleteMeeting,
}) => {
  if (!meeting) return null;

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const formatDateTime = (date: string, timezone: string) => {
    return new Date(date).toLocaleString([], {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      month: 'short',
      day: 'numeric',
      timeZone: timezone,
    });
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      onClick={handleBackdropClick}
    >
      <div className="bg-white dark:bg-gray-800 rounded-lg p-8 max-w-3xl w-full mx-auto relative max-h-[90vh] overflow-y-auto">
        <div className="absolute top-4 right-4 flex space-x-2">
          <button
            onClick={() => {
              if (confirm('Are you sure you want to delete this meeting?')) {
                onDeleteMeeting(meeting);
              }
            }}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </button>
          <button
            onClick={() => {
              onEditMeeting(meeting);
            }}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
          </button>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">{meeting.name}</h2>

        <div className="space-y-4">
          <div className="flex items-start space-x-2">
            <svg
              className="w-5 h-5 mt-0.5 text-gray-500 dark:text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <div className="text-gray-700 dark:text-gray-300">
              {formatDateTime(meeting.starts_at, meeting.timezone)}
              {' - '}
              {formatDateTime(meeting.ends_at, meeting.timezone)}
              <div className="text-sm text-gray-500 dark:text-gray-400">{meeting.timezone}</div>
            </div>
          </div>

          {meeting.data?.description && (
            <div className="flex items-start space-x-2">
              <svg
                className="w-5 h-5 mt-0.5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
              <div
                className="text-gray-700 dark:text-gray-300"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(meeting.data?.description || ''),
                }}
              />
            </div>
          )}

          {meeting.location && (
            <div className="flex items-start space-x-2">
              <svg
                className="w-5 h-5 mt-0.5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <div className="text-gray-700 dark:text-gray-300">{meeting.location}</div>
            </div>
          )}

          <div className="mt-4 p-4 bg-gray-50 dark:bg-gray-700 rounded-lg overflow-auto max-h-96">
            <pre className="text-xs text-gray-600 dark:text-gray-300">{JSON.stringify(meeting, null, 2)}</pre>
          </div>

          <div className="flex space-x-3 mt-6">
            {meeting.data?.hangout_link && (
              <button
                onClick={() => window.open(meeting.data.hangout_link, '_blank')}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex items-center space-x-2"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                  />
                </svg>
                <span>Join Google Meet</span>
              </button>
            )}

            {/* <button
              onClick={() => (window.location.href = `mailto:${meeting.organizer_email}`)}
              className="text-gray-700 dark:text-gray-300 px-4 py-2 rounded border border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center space-x-2"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              <span>Send email</span>
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};
