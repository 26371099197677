import React from 'react';

interface NavigationBarProps {
  commentsCount?: number;
  pathname: string;
}

function NavigationBar({ commentsCount, pathname }: NavigationBarProps): JSX.Element {
  return (
    <nav className="bg-white dark:bg-gray-900 w-full border-t border-gray-200 dark:border-gray-800">
      <div className="p-4 w-full justify-between text-xs text-center">
        <p className="text-sm text-gray-600 dark:text-gray-400">© 2024 SalesCal. All rights reserved.</p>
      </div>
    </nav>
  );
}

export default NavigationBar;
