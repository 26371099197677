import ReactOnRails from 'react-on-rails';
// eslint-disable-next-line import/no-webpack-loader-syntax
import 'expose-loader?exposes=$,jQuery!jquery';
import 'jquery-ujs';

import RouterApp from '../apps/RouterApp';
import NavigationBarApp from '../apps/NavigationBarApp';
import '../controllers';

ReactOnRails.setOptions({
  // traceTurbolinks: process.env.TRACE_TURBOLINKS, // eslint-disable-line no-undef
  // process.env.TRACE_TURBOLINKS -> error: development is not defined
  traceTurbolinks: true,
});

ReactOnRails.register({
  RouterApp,
  NavigationBarApp,
});
