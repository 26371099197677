export const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const getCalendarDays = (currentDate: Date): Date[] => {
  const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  const startWeek = new Date(startDate);
  startWeek.setDate(startWeek.getDate() - startWeek.getDay());

  const endWeek = new Date(endDate);
  endWeek.setDate(endDate.getDate() + (6 - endDate.getDay()));

  const days: Date[] = [];
  const currentDay = new Date(startWeek);

  while (currentDay <= endWeek) {
    days.push(new Date(currentDay));
    currentDay.setDate(currentDay.getDate() + 1);
  }

  return days;
};

export const getWeekDays = (currentDate: Date): Date[] => {
  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

  const days: Date[] = [];
  for (let i = 0; i < 7; i++) {
    const day = new Date(startOfWeek);
    day.setDate(day.getDate() + i);
    days.push(day);
  }
  return days;
};

export const formatTime = (date: Date, format: 'friendly' | 'default' = 'default') => {
  if (format === 'friendly') {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'pm' : 'am';
    const friendlyHours = hours % 12 || 12;

    // Only show minutes if they're not zero
    return minutes === 0
      ? `${friendlyHours}${period}`
      : `${friendlyHours}:${minutes.toString().padStart(2, '0')}${period}`;
  }

  // Default format (original implementation)
  return date.toLocaleTimeString('default', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
};
