import React from 'react';
import { Meeting } from '../types';

interface WeekMeetingProps {
  meeting: Meeting;
  currentTimezone: string;
  onMeetingClick: (meeting: Meeting) => void;
  cellHeight: number;
}

export const WeekMeeting: React.FC<WeekMeetingProps> = ({
  meeting,
  currentTimezone,
  onMeetingClick,
  cellHeight,
}) => {
  const startTime = new Date(meeting.starts_at);
  const endTime = new Date(meeting.ends_at);
  const topPosition = (startTime.getHours() * 60 + startTime.getMinutes()) * (cellHeight / 60);
  const height =
    (endTime.getHours() * 60 + endTime.getMinutes() - (startTime.getHours() * 60 + startTime.getMinutes())) *
    (cellHeight / 60);

  return (
    <div
      key={meeting.id}
      className="absolute left-1 right-1 bg-blue-100 text-blue-700 rounded p-1 text-sm dark:bg-blue-900 dark:text-blue-200 cursor-pointer hover:opacity-80 calendar-meeting z-50"
      id={`meeting-${meeting.id}`}
      style={{
        top: `${topPosition}px`,
        height: `${height}px`,
      }}
      onClick={() => onMeetingClick(meeting)}
    >
      {meeting.name}
      <div className="text-xs text-gray-600 dark:text-gray-400">
        {startTime.toLocaleTimeString([], {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
          timeZone: currentTimezone,
        })}
      </div>
    </div>
  );
};
